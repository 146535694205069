<template>
<div>

</div>
</template>

<script>
export default {
  name: 'WeAreWorking',
};
</script>
